<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <v-card :loading="loading">
      <v-card-title class="headline">
        <v-icon color="blue">
          mdi-clipboard-edit-outline
        </v-icon>
        ผลการประเมิน
        <span class="subtitle-2"> {{ type == 'add' ? '' : '(แก้ไข)' }}</span>
      </v-card-title>
      <v-container fluid>
        <v-form v-model="valid" ref="form" lazy-validation>
          <!-- จุดตรวจ -->
          <v-row>
            <v-col cols="12" sm="2"><b>จุดตรวจ :</b></v-col>
            <v-col cols="12" sm="4">{{ detail.name }}</v-col>
            <v-col cols="12" sm="2"><b>สถานที่ :</b></v-col>
            <v-col cols="12" sm="4">{{ detail.address }}</v-col>
            <v-col cols="12" sm="2"><b>หัวหน้าจุดตรวจ :</b></v-col>
            <v-col cols="12" sm="4">{{ detail.chief }}</v-col>
            <v-col cols="12" sm="2"><b>เบอร์โทรหัวหน้าจุดตรวจ :</b></v-col>
            <v-col cols="12" sm="4">{{ detail.chief_phone }}</v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-data-table v-if="list && list.length > 0" :headers="headers" :items="list" class="elevation-1">
                <template v-slot:[`item.eval1`]="{ item }">
                  <template v-if="item.eval1 == 1">
                    <v-icon color="green">mdi-check</v-icon>
                    พร้อม
                  </template>
                  <template v-else>
                    <v-icon color="red">mdi-close-circle</v-icon>
                    ไม่พร้อม
                  </template>
                </template>
                <template v-slot:[`item.eval2`]="{ item }">
                  <template v-if="item.eval2 == 1">
                    <v-icon color="green">mdi-check</v-icon>
                    พร้อม
                  </template>
                  <template v-else>
                    <v-icon color="red">mdi-close-circle</v-icon>
                    ไม่พร้อม
                  </template>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ thDate(item.created_at) }}
                </template>

                <template v-slot:[`item.images`]="{ item }">
                  <v-icon v-if="item.images && item.images[0]" @click.prevent.stop="viewImage(item.images[0])" color="green" v-on="on" v-bind="attrs">
                    mdi-image
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn text color="red" @click="cancel">ปิด</v-btn>
      </v-card-actions>
    </v-card>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
  </v-dialog>
</template>

<script>
import { show, store, update, checkpoint } from '@/api/evaluation'
import { destroy as destroyImage } from '@/api/picture'
// import { getCharge, getChargeType } from '@/api/charge'
// import { havePast } from '@/api/checkpoint'
export default {
  methods: {
    clear() {

    },
    async init(item, type) {
      this.detail = item;
      checkpoint({ checkpoint_id: item.id }).then(res => {
        this.list = res;
      })
    },
    cancel() {
      // this.data = Object.assign({}, this.data_init)
      // this.$refs.form.resetValidation()
      // this.file = null
      this.dialog = false
    },
    thDate(d) {
      return this.moment(d)
        .add(543, 'year')
        .format('D MMMYYYY HH:mm')
    },
    viewImage(src) {
      var image = new Image();
      image.src = src;
      var w = window.open("");
      w.document.write(image.outerHTML);
    }
  },
  data: () => ({
    headers: [
      { text: 'ความพร้อมของกำลังเจ้าหน้าที่ประจำด่านตรวจ', value: 'eval1' },
      { text: 'ความพร้อมของวัสดุอุปกรณ์ประจำด่านตรวจ', value: 'eval2' },
      { text: 'ปัญหา ข้อขัดข้อง และอื่นๆ', value: 'detail' },
      { text: 'ผู้ประเมิน', value: 'fullname' },
      { text: 'วันที่ประเมิน', value: 'created_at' },
      { text: 'ภาพถ่าย', value: 'images' }
    ],
    data: {},
    data_init: { eval1: "1", eval2: "1", detail: "", images: [] },
    detail: {},
    valid: true,
    loading: false,
    levels: [],
    list: [],
    type: 'add',
    datemenu: false,
    bdatemenu: false,
    readerCompleted: false,
    file: null,
    checkpoints: [],
    officers: [],
    fullName: ""
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    }
  },
  watch: {

  },
  async created() {

  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
}
</script>

<style>

</style>
