<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <v-card :loading="loading">
      <v-card-title class="headline">
        <v-icon color="blue">
          mdi-clipboard-edit-outline
        </v-icon>
        ประเมินผลการปฏิบัติ
        <span class="subtitle-2"> {{ type == 'add' ? '' : '(แก้ไข)' }}</span>
      </v-card-title>
      <v-container fluid>
        <v-form v-model="valid" ref="form" lazy-validation>
          <!-- จุดตรวจ -->
          <v-row>
            <v-col cols="12" sm="2"><b>จุดตรวจ :</b></v-col>
            <v-col cols="12" sm="4">{{ detail.name }}</v-col>
            <v-col cols="12" sm="2"><b>สถานที่ :</b></v-col>
            <v-col cols="12" sm="4">{{ detail.address }}</v-col>
            <v-col cols="12" sm="2"><b>หัวหน้าจุดตรวจ :</b></v-col>
            <v-col cols="12" sm="4">{{ detail.chief }}</v-col>
            <v-col cols="12" sm="2"><b>เบอร์โทรหัวหน้าจุดตรวจ :</b></v-col>
            <v-col cols="12" sm="4">{{ detail.chief_phone }}</v-col>
          </v-row>

          <v-row>
            <v-col sm="12">1. ความพร้อมของกำลังเจ้าหน้าที่ประจำด่านตรวจ</v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-radio-group :disabled="type === 'view'" v-model="data.eval1">
                <v-radio label="พร้อม" value="1"></v-radio>
                <v-radio label="ไม่พร้อม" value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">2. ความพร้อมของวัสดุอุปกรณ์ประจำด่านตรวจ</v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-radio-group :disabled="type === 'view'" v-model="data.eval2">
                <v-radio label="พร้อม" value="1"></v-radio>
                <v-radio label="ไม่พร้อม" value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <!-- text -->
          <v-row>
            <v-col sm="12">3. ปัญหา ข้อขัดข้อง หรือรายละเอียดอื่นๆ</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea :disabled="type === 'view'" label="ปัญหา ข้อขัดข้อง และอื่นๆ" auto-grow v-model="data.detail"
                :rules="reqRule"></v-textarea>
            </v-col>
          </v-row>

          <!-- image -->
          <v-row v-if="type !== 'view'">
            <v-col cols="12">
              <!-- image gallery edit-->
              <v-row v-if="type == 'edit'">
                <v-col v-for="(im, i) in data.imagesWithId" :key="i" class="d-flex child-flex" cols="3">
                  <v-card>
                    <v-card-text>
                      <v-fab-transition>
                        <v-btn fab small color="red" absolute top right dark @click="deleteImage(im.id)">
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-fab-transition>
                      <v-img :src="im.data" :lazy-src="im.data" aspect-ratio="1" class="grey lighten-2">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- end image gallery edit -->

              <!-- image gallery -->
              <v-row>
                <v-col v-for="(im, i) in data.images" :key="i" class="d-flex child-flex" cols="3">
                  <v-img :src="im" :lazy-src="im" aspect-ratio="1" class="grey lighten-2">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <!-- end image gallery -->

              <v-file-input label="เลือกไฟล์รูป" v-model="file" multiple show-size @change="handleFileChange"
                accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"></v-file-input>
            </v-col>
          </v-row>
          <v-carousel v-if="data.images && data.images.length > 0 && type === 'view'">
            <v-carousel-item v-for="(item, i) in data.images" :src="item" :key="i"></v-carousel-item>
          </v-carousel>
          <v-row v-show="fullName">
            <v-col sm="12">
              <h4>ผู้ประเมิน {{ fullName }}</h4>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn text @click="cancel" v-show="type !== 'view'">ยกเลิก</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-3" v-show="type !== 'view'" @click="submit" dark>
          <v-icon>
            mdi-content-save
          </v-icon>
          บันทึกข้อมูล
        </v-btn>
        <v-btn text color="red" @click="cancel" v-show="type === 'view'">ปิด</v-btn>
      </v-card-actions>
    </v-card>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
  </v-dialog>
</template>

<script>
import { show, store, update, checkpoint } from '@/api/evaluation'
import { destroy as destroyImage } from '@/api/picture'
// import { getCharge, getChargeType } from '@/api/charge'
// import { havePast } from '@/api/checkpoint'
export default {
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        const saveData = {
          ...this.data,
          checkpoint_id: this.detail?.id
        }
        store(saveData)
          .then(res => {
            this.$toast.success({
              title: 'Success',
              message: 'เพิ่มข้อมูลเรียบร้อยแล้ว',
            })
            this.$emit('saved')
            this.cancel()
          })
          .catch(err => {
            console.log(err)
            this.$toast.error({
              title: 'Error',
              message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
            })
          })
          .finally(() => {
            this.loading = false
          })
        // if (this.type == 'add') {
        //   const saveData = {
        //     ...this.data,
        //     checkpoint_id: this.detail?.id
        //   }
        //   store(saveData)
        //     .then(res => {
        //       this.$toast.success({
        //         title: 'Success',
        //         message: 'เพิ่มข้อมูลเรียบร้อยแล้ว',
        //       })
        //       this.$emit('saved')
        //       this.cancel()
        //     })
        //     .catch(err => {
        //       console.log(err)
        //       this.$toast.error({
        //         title: 'Error',
        //         message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
        //       })
        //     })
        //     .finally(() => {
        //       this.loading = false
        //     })
        // } else {
        //   // Edit
        //   update(this.data)
        //     .then(res => {
        //       if (res.success) {
        //         this.$toast.success({
        //           title: 'Success',
        //           message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
        //         })
        //         this.cancel
        //         this.$emit('saved')
        //       }
        //     })
        //     .catch(err => {
        //       this.$toast.error({
        //         title: 'Error',
        //         message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
        //       })
        //     })
        //     .finally(() => {
        //       this.loading = false
        //     })
        // }
      }
    },
    clear() {
      this.$refs.form.reset()
    },
    async init(item, type) {
      this.detail = item;
      if (type == 'add') {
        this.type = 'add'
        if (this.$refs.form != undefined) this.$refs.form.resetValidation()
        this.data = Object.assign({}, this.data_init)
      } else if (type == 'edit') {
        this.type = 'edit'
        const data = Object.assign({}, await show(item.evaluation_id))
        this.fullName = data.fullname;
        // this.data = { images: [], ...this.data }
        this.data = {
          eval1: data.eval1 + "",
          eval2: data.eval2 + "",
          detail: data.detail,
          images: data.images
        }
        // this.cChanged()
        // this.ctChange()
      } else {
        this.type = 'view'
        // const data = Object.assign({}, await show(item.evaluation_id))
        const data = Object.assign({}, await checkpoint({ checkpoint_id: item.id }))
        // this.data = { images: [], ...this.data }
        this.fullName = data.fullname;
        this.data = {
          eval1: data.eval1 + "",
          eval2: data.eval2 + "",
          detail: data.detail,
          images: data.images
        }
      }
    },
    cancel() {
      this.data = Object.assign({}, this.data_init)
      this.$refs.form.resetValidation()
      this.file = null
      this.dialog = false
    },
    async deleteImage(id) {
      const confirm = await this.$refs.confirm.open('ลบรูปภาพ', 'ต้องการลบรูปภาพนี้ ?', { color: 'red' })
      if (confirm) {
        destroyImage(id).then(res => {
          const index = this.data.imagesWithId.findIndex(i => i.id == id)
          this.data.imagesWithId.splice(index, 1)
        })
      }
    },
    // utils
    handleFileChange(files) {
      if (!Array.isArray(files) || files.length == 0) {
        this.data.images = []
        return
      }

      files.forEach(file => {
        let reader = new FileReader()
        reader.onload = e => {
          // console.log(e.target.result)
          this.data.images.push(e.target.result)
        }

        reader.onabort = () => {
          this.readerCompleted = true
        }
        reader.onloadend = () => {
          reader.abort()
        }
        reader.readAsDataURL(file)
      })
    },
    date_format(d) {
      return (
        this.moment(d)
          .add(543, 'year')
          .format('D MMMYYYY') || null
      )
    },
  },
  data: () => ({
    data: {},
    data_init: { eval1: "1", eval2: "1", detail: "", images: [] },
    detail: {},
    valid: true,
    loading: false,
    levels: [],
    type: 'add',
    datemenu: false,
    bdatemenu: false,
    readerCompleted: false,
    file: null,
    checkpoints: [],
    officers: [],
    fullName: "",
    arresttypes: [
      { value: 1, text: 'จับกุม' },
      { value: 2, text: 'ว่ากล่าวตักเตือน' },
      { value: 3, text: 'รายงานผลการปฏิบัติ' },
    ],
    charges: [],
    chargetype: [],
    detail_init:
      'เรียนผู้บังคับบัญชา เพื่อโปรดทราบ\r\n #date เวลา #time #officers ได้ทำการ #type (ผู้ต้องหา) เนื่องมาจาก #charges ',
    arrestreport: {},
    reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
    cidRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{13}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
    phoneRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{9,10}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
    timeRules: [v => /^((?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?))$/.test(v) || 'กรอกเวลาให้ถูกต้อง'],
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    }
  },
  watch: {
    bdatemenu(val) {
      val && setTimeout(() => (this.$refs.bdatePicker.activePicker = 'YEAR'))
    },
  },
  async created() {
    // this.checkpoints = await havePast()
    // this.charges = await getCharge()
    // this.chargetype = await getChargeType()
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
}
</script>

<style>

</style>
