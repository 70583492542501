var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.lists,"options":_vm.options,"items-per-page":_vm.tableMeta.per_page,"server-items-length":_vm.tableMeta.total,"item-class":_vm.itemBg,"footer-props":_vm.footer},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.tableMeta, "per_page", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.tableMeta, "per_page", $event)},"click:row":_vm.rowClick,"dblclick:row":_vm.rowDblClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.is_active == 1)?[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(item.name)+" ")]:[_vm._v(" "+_vm._s(item.name)+" ")]]}},{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.thDate(item.start_at))+" ")]}},{key:"item.end_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.thDate(item.end_at))+" ")]}},{key:"item.types",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.types.map(function (elem) { return elem.name; }).join(","))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.level_name!=='รองสารวัตร' || _vm.level_name!=='ผู้บังคับหมู่')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('edit-evaluation', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-clipboard-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v("ทำแบบประเมิน")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.evaluation_id),expression:"item.evaluation_id"}],attrs:{"color":"blue"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('view-evaluation', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-outline ")])]}}],null,true)},[_c('span',[_vm._v("ดูแบบประเมิน")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }