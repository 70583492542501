<template>
  <v-container fluid>
    <v-card elevation="2" :loading="loading">
      <v-card-title primary-title>
        <div>
          <div class="headline">
            <v-icon color="green">
              mdi-account-check
            </v-icon>
            การตรวจเยี่ยมและประเมินผลจุดตรวจของผู้บังคับบัญชา (ระดับ สว. ขึ้นไป)
          </div>
          
          <span class="grey--text subtitle-2">ข้อมูลจุดตรวจที่มีในวันนี้</span>
          <v-alert type="red" dense>หากยังไม่ปรับสถานะเป็นตั้งจริง จะไม่แสดงจุดตรวจ/ด่านตรวจในตารางนี้</v-alert>
          <v-alert type="red" dense>เฉพาะ User ระดับ สว. ขึ้นไปจึงสามารถบันทึกแบบประเมินได้</v-alert>
          <!-- <v-alert type="info" dense>กรณีจุดตรวจใดมีการปฏิบัติจริง กรุณากดปุ่ม ดำเนินการตั้งจุดตรวจด้วย (ปุ่ม
            <v-icon>mdi-traffic-cone</v-icon>)(ถ้าไม่ตั้งจุดตรวจจะไม่สามารถรายงานผลการปฏิบัติได้) และ
            <strong><u>ไม่ต้องกดยกเลิก</u></strong> เมื่อเลิกตั้งจุดตรวจ
          </v-alert>
          <v-alert type="info" dense>
            **ในกรณีที่ได้บันทึกแผนการตั้งจุดตรวจไว้แล้ว ถ้าไม่สามารถตั้งจริงได้ ไม่ต้องดำเนินการใดๆ
          </v-alert>
          <v-alert type="warning" dense>
            *** จุดตรวจที่ยังไม่ผ่านการอนุมัติจะไม่สามารถตั้งจุดตรวจได้
          </v-alert> -->
        </div>
        <v-spacer></v-spacer>
        <div style="display: flex; width: 100%; justify-content: flex-end">
          <div>
            <!-- <v-switch
              v-model="showActive"
              :disabled="loading"
              label="แสดงเฉพาะที่ตั้งจริง"
              :hide-details="true"
              inset
            ></v-switch> -->
            <!-- <v-btn color="indigo darken-2" dark class="mt-4" @click="exportClick">
              Export Excel
            </v-btn> -->
          </div>
        </div>

      </v-card-title>
      <!-- <checkpoint-table 
        ref="cpTable" 
        @row-click="rowClick" 
        @row-dbl-click="rowDblClick" 
        :show-active="showActive"
        @show-qrcode="showQrcode">
      </checkpoint-table> -->
      <checkpoint-table
        ref="cpTable"
        @edit-evaluation="editClick"
        @view-evaluation="viewClick"
        :show-active="showActive"
      ></checkpoint-table>
    </v-card>

    <evaluation-dialog ref="dialog" v-model="show" :type="modify" @saved="onSave"></evaluation-dialog>
    <view-dialog ref="dialogView" v-model="showView"></view-dialog>
  </v-container>
</template>

<script>
import checkpointTable from './partials/checkpointTable'
import evaluationDialog from './partials/evaluationDialog'
import viewDialog from './partials/viewDialog'
import { qrcode, exportExcel } from '@/api/checkpoint'
import { mapGetters } from 'vuex'
export default {
  methods: {
    new() {
      this.$refs.dialog.init(null, 'add')
      this.show = true
    },
    edit(id) {
      this.$refs.dialog.init(id, 'edit')
      this.show = true
    },
    exportClick() {
      this.loading = true
      const params = {
        bureau: this.bureauId,
        division: this.divisionId,
        station: this.stationId,
        showActive: true
      }
      exportExcel(params)
      .then(response => {
        this.loading = false
      })
    },
    editClick(item) {
      this.$refs.dialog.init(item, 'add')
      this.show = true
      // this.$refs.dialog.init(id, 'add')
      // this.$refs.dialog.open()
    },
    viewClick(item) {
      this.$refs.dialogView.init(item, 'view')
      this.showView = true
      // this.$refs.dialog.init(id, 'add')
      // this.$refs.dialog.open()
    },
    rowDblClick() { 
      
    },
    onSave() {
      this.show = false
      this.$refs.cpTable.getList()
    }
  },
  components: {
    checkpointTable,
    evaluationDialog,
    viewDialog
  },
  data() {
    return {
      loading: false,
      show: false,
      showView: false,
      showActive: true,
      modify: 'add',
    }
  },
  computed: {
    ...mapGetters('Appfilter', ['bureauId', 'divisionId', 'stationId', 'dateStart', 'dateEnd']),
    ...mapGetters('User', ['name', 'position', 'role', 'avatarPicture', 'station', 'phone', 'level_name']),
  },
  async created() { },
}
</script>

<style>

</style>
