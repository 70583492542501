var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"900"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"headline"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-clipboard-edit-outline ")]),_vm._v(" ผลการประเมิน "),_c('span',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.type == 'add' ? '' : '(แก้ไข)'))])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('b',[_vm._v("จุดตรวจ :")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(_vm._s(_vm.detail.name))]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('b',[_vm._v("สถานที่ :")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(_vm._s(_vm.detail.address))]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('b',[_vm._v("หัวหน้าจุดตรวจ :")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(_vm._s(_vm.detail.chief))]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('b',[_vm._v("เบอร์โทรหัวหน้าจุดตรวจ :")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(_vm._s(_vm.detail.chief_phone))])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[(_vm.list && _vm.list.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list},scopedSlots:_vm._u([{key:"item.eval1",fn:function(ref){
var item = ref.item;
return [(item.eval1 == 1)?[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]),_vm._v(" พร้อม ")]:[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")]),_vm._v(" ไม่พร้อม ")]]}},{key:"item.eval2",fn:function(ref){
var item = ref.item;
return [(item.eval2 == 1)?[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]),_vm._v(" พร้อม ")]:[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")]),_vm._v(" ไม่พร้อม ")]]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.thDate(item.created_at))+" ")]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [(item.images && item.images[0])?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.viewImage(item.images[0])}}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v(" mdi-image ")]):_vm._e()]}}],null,true)}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.cancel}},[_vm._v("ปิด")])],1)],1),_c('bjp-confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }